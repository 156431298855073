<template>
  <el-dialog
    title="销量清零设置"
    :visible.sync="setClearRulesDialog"
    width="25%"
    class="setClearRulesDialog"
  >
    <p class="setClearRulesTip">对该商品的真实销量可进行如下清零方式：</p>
    <el-radio-group class="setClearRulesRadioGroup" v-model="saleClearRules">
      <el-radio :label="1">每日清零</el-radio>
      <el-radio :label="7">每7天清零</el-radio>
      <el-radio :label="30">每30天清零</el-radio>
      <el-radio :label="customDays"
        >每
        <el-input
          class="setClearRulesinput"
          v-model="customDays"
          oninput="value=value.replace(/\D|^-/g,'')"
        ></el-input>
        天清零</el-radio
      >
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
      <el-button @click="setClearRulesDialog = false">取消</el-button>
      <el-button type="primary" @click="saveRules">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    pid: {
      // 定义pid属性的类型，可以是Number或String类型
      type: [Number, String],
      // 设置pid属性的默认值为空字符串
      default: "",
    },
  },
  data() {
    return {
      // 设置清除规则对话框的显示状态
      setClearRulesDialog: false,
      // 销售清除规则
      saleClearRules: 1,
      // 自定义天数
      customDays: "",
    };
  },
  methods: {
    // 保存规则
    saveRules() {
      const params = {
        clearRuleDays: this.saleClearRules,
        productId: this.pid,
      };
      this.$api.goods.editSaleClearRules(params).then((res) => {
        if (Number(res.code) === 200) {
          this.$message.success(res.message);
          this.setClearRulesDialog = false;
          this.$emit("editSuccess");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.setClearRulesDialog {
  .setClearRulesTip {
    margin-bottom: 20px;
  }
  .setClearRulesRadioGroup {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-bottom: 15px;
      .setClearRulesinput {
        width: 20%;
      }
    }
  }
}
</style>